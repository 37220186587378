import { getSampleFileApi } from "api/endpoints/data-mapping-import.api"
import { Button } from "components/button/button"
import { DragAndDropUploadZone } from "components/drag-and-drop-upload-zone/drag-and-drop-upload-zone"
import { Input } from "components/form-components/input/input"
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RootState } from "store"
import { downloadBase64Csv } from "utils"
import { LINK_CLASS_NAME } from "utils/constants"

type StepOneProps = {
  step: number
  setStep: (step: number) => void
  mapName: string
  setMapName: React.Dispatch<React.SetStateAction<string>>
  file: File | null
  setFile: React.Dispatch<React.SetStateAction<File | null>>
  onCancel: () => void
}

export const StepOne = ({
  step,
  setStep,
  mapName,
  setMapName,
  file,
  setFile,
  onCancel,
}: StepOneProps) => {
  const containerRef = useRef(null)
  const [isVertical, setIsVertical] = useState(false)

  const selectedWorkspaceId = useSelector(
    (state: RootState) => state.workspaces.selectedWorkspaceId,
  )

  const navigate = useNavigate()

  const isNextDisabled = file === null || mapName === ""

  const onMapNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMapName(event.target.value)
  }

  useEffect(() => {
    const parentElement = containerRef.current

    // ResizeObserver to check for height changes
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const height = entry.contentRect.height
        setIsVertical(height > 390) // Set the height threshold here (500px in this case)
      }
    })

    if (parentElement) {
      resizeObserver.observe(parentElement)
    }

    return () => {
      if (parentElement) {
        resizeObserver.unobserve(parentElement)
      }
    }
  }, [])

  return (
    <div className="flex flex-col h-full">
      <div className="step-body">
        <div className="flex justify-between pb-3 border-b border-gray-200 mb-[30px]">
          <div className="flex flex-col">
            <div className="text-title-3 font-bold text-gray-900 pb-[5px]">Details</div>
            <div className="text-card text-gray-500 font-medium">
              Add a map name and upload the CSV file.
            </div>
          </div>
          <Button variant="outlined" className="self-end" onClick={onCancel}>
            Cancel Upload
          </Button>
        </div>
        <div className="text-sub-headline text-gray-900 font-semibold pb-[5px]">Name</div>
        <div className="text-sub-headline text-gray-500 font-medium pb-3">
          Please enter the name of the map you want to create.
        </div>
        <div
          ref={containerRef}
          className={`flex h-full gap-6 overflow-hidden overflow-y-auto ${
            isVertical ? "flex-col" : "flex-row"
          }`}
        >
          <Input
            className="max-w-[422px] h-10 mb-8"
            placeholder="Type here..."
            value={mapName}
            onChange={onMapNameChange}
          />
          <div>
            <DragAndDropUploadZone
              className="w-[422px] h-[250px] border border-dashed border-blue-600 rounded-lg bg-gray-50"
              file={file}
              setFile={setFile}
            />
            <div className="text-caption-1 text-gray-500 flex pt-3">
              <div> Don’t know hot to prepare the file? &nbsp;</div>
              <div
                className={LINK_CLASS_NAME}
                onClick={() => {
                  if (selectedWorkspaceId) {
                    getSampleFileApi(selectedWorkspaceId).then(downloadBase64Csv)
                  }
                }}
              >
                Get sample here!
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="step-button-bar">
        <Button
          variant="outlined"
          onClick={() => {
            navigate(`/main/workspaces/${selectedWorkspaceId}/edit/upload`, {
              state: 1,
            })
          }}
          className="mr-3"
        >
          Back
        </Button>
        <Button
          variant="primary"
          disabled={isNextDisabled}
          onClick={() => {
            setStep(step + 1)
          }}
        >
          Next Step
        </Button>
      </div>
    </div>
  )
}
