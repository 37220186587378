import { useDispatch } from "react-redux"
import { RootDispatch } from "store"
import { Icon, ICONS } from "components/icon/icon"
import { RetailerSelectorOption } from "common/types/RetailerSelectorOption.type"
import { NewAuditCard } from "pages/audits/new-audit/new-audit-card/new-audit-card"
import { UploadType } from "components/select-retailer-uncontrolled/select-retailer-uncontrolled"
import { useModal } from "context/modal-context"
import { DeleteRetailerModal } from "./delete-retailer-modal/delete-retailer-modal"
import { SourceModal } from "components/source-modal/source-modal"
import { EllipsisRetailerLabel } from "./retailer-selector/retailer-selector"

type Props = {
  options: RetailerSelectorOption[]
  disabled?: boolean
  uploadType: UploadType
  isCustom?: boolean
  selectedRetailers: RetailerSelectorOption[]
  setSelectedRetailers: React.Dispatch<React.SetStateAction<RetailerSelectorOption[]>>
}

export const RetailerSelectorUncontrolled: React.FC<Props> = ({
  options,
  disabled = false,
  uploadType,
  isCustom,
  selectedRetailers,
  setSelectedRetailers,
}) => {
  const dispatch = useDispatch<RootDispatch>()
  const { setModal, unSetModal } = useModal()

  const handleRetailerSelect = (option: RetailerSelectorOption) => {
    if (uploadType === UploadType.uploadMasterVersionContent) {
      const isOptionSelected = selectedRetailers.find(
        (retailer) => retailer.value === option.value,
      )

      const updatedRetailers = isOptionSelected
        ? selectedRetailers.filter((retailer) => retailer.value !== option.value)
        : [...selectedRetailers, option]

      setSelectedRetailers(updatedRetailers)
    } else {
      if (selectedRetailers[0]?.value === option.value) {
        setSelectedRetailers([])
      } else {
        setSelectedRetailers([option])
      }
    }
  }

  if (options.length === 0) {
    return (
      <div className="flex w-full m-10 items-center justify-center">
        <Icon icon={ICONS.BELL} size={4.5} />
        <span className="text-body font-medium text-gray-900 ml-2">
          No item was found.
        </span>
      </div>
    )
  }

  return (
    <div className="retailer-selector">
      {options.map((option) => (
        <NewAuditCard
          key={option.value}
          selected={
            !!selectedRetailers.find((retailer) => retailer.value === option.value)
          }
          disabled={disabled}
          checkbox={uploadType === UploadType.uploadMasterVersionContent}
          onClick={() => handleRetailerSelect(option)}
          title={
            <div className="pb-3 min-h-12 max-h-12">
              {option.logoUrl ? (
                <img
                  className="h-full w-full object-contain"
                  src={option.logoUrl}
                  alt={option.label}
                />
              ) : (
                <Icon
                  className="text-gray-300 mx-auto"
                  size={10}
                  icon={ICONS.CIRCLE_STACK}
                />
              )}
            </div>
          }
          body={<EllipsisRetailerLabel label={option.label} id={option.value} />}
          isThreeDots={uploadType === UploadType.uploadAsASource && isCustom}
          handleEdit={() => {
            setModal(<SourceModal closeModal={() => unSetModal()} retailer={option} />)
          }}
          handleDelete={() =>
            setModal(
              <DeleteRetailerModal
                onDelete={() => {
                  dispatch.retailers.deleteRetailer(option.value)
                }}
              />,
            )
          }
        />
      ))}
    </div>
  )
}
