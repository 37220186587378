import { useEffect, useRef, useState } from "react"
import {
  unstable_useBlocker,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { RootDispatch, RootState } from "store"
import {
  cancelUploadApi,
  duplicateDataMappingApi,
  getDataMappingByIdApi,
  importDataMappingApi,
  updateDataMappingApi,
} from "api/endpoints/data-mapping-import.api"
import { useHashRouting } from "common/hooks/useHashRouting"
import { RetailerSelectorOption } from "common/types/RetailerSelectorOption.type"
import { Stepper } from "components/stepper/stepper"
import { StepFour } from "./fieldMappingSteps/stepFour"
import { StepThree } from "./fieldMappingSteps/stepThree"
import { StepTwo } from "./fieldMappingSteps/stepTwo"
import { StepOne } from "./fieldMappingSteps/stepOne"
import { InitialImportResultModel } from "common/models/InitialImportResultModel"
import { UploadType } from "components/select-retailer-uncontrolled/select-retailer-uncontrolled"
import { Loader } from "components/loader/loader"
import { ProductImportCsvModel } from "common/models/ProductImportCsvModel"
import { useModal } from "context/modal-context"
import { ConfirmationModal } from "common/modals/confirmation-modal/confirmation-modal"
import { Icon, ICONS } from "components/icon/icon"

import "./fieldMappingPage.scss"
import { usePrevious } from "common/hooks/usePrevious"

export interface FourthStepTypes {
  rows: ProductImportCsvModel[]
  dataMappingId: string
}

export const FieldMappingPage = () => {
  const dispatch = useDispatch<RootDispatch>()
  const { setModal, isOpen, unSetModal } = useModal()
  const [step, setStep] = useHashRouting<number>(
    (stepToCheck) => stepToCheck >= 0 && stepToCheck <= 3,
    0,
  )
  const [mapId, setMapId] = useState("")
  const mapIdRef = useRef(mapId)
  const [mapName, setMapName] = useState("")
  const [file, setFile] = useState<File | null>(null)
  const [selectedRetailers, setSelectedRetailers] = useState<RetailerSelectorOption[]>([])
  const [mappings, setMappings] = useState<{ [key: string]: string | null }[]>([])
  const [selectedUploadType, setSelectedUploadType] = useState(
    UploadType.uploadMasterVersionContent,
  )
  const [loading, setLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [hasConfirmed, setHasConfirmed] = useState(false)
  const [fourthStepData, setFourthStepData] = useState<FourthStepTypes>({
    rows: [],
    dataMappingId: "",
  })

  const previousIsOpen = usePrevious(isOpen)

  const allRetailers = useSelector((state: RootState) => state.retailers.allRetailers)
  const selectedWorkspaceId = useSelector(
    (state: RootState) => state.workspaces.selectedWorkspaceId,
  )

  const blocker = unstable_useBlocker((args) => {
    if (isSaving) {
      return false
    }

    const isStep =
      args.nextLocation.pathname.includes("#edit") ||
      args.nextLocation.pathname.includes("main/field-mapping") ||
      args.nextLocation.pathname.includes("#duplicate")

    return args.nextLocation.pathname !== "/" && !isStep
  })

  const { uploadId } = useParams()

  const navigate = useNavigate()

  const location = useLocation()
  const isDuplicate = location.pathname.includes("duplicate")

  const importDataMapping = () => {
    setLoading(true)
    const formPayload = new FormData()
    formPayload.append("fileName", file?.name ?? "")
    formPayload.append("name", mapName)
    formPayload.append("type", selectedUploadType)
    selectedRetailers.forEach((retailer) =>
      formPayload.append("retailerIds", retailer.value),
    )

    if (file?.size) {
      formPayload.append("file", file, file.name)
    } else {
      formPayload.set("file", "")
    }

    //DUPLICATE
    if (isDuplicate && uploadId) {
      formPayload.append("id", uploadId)

      return (
        selectedWorkspaceId &&
        duplicateDataMappingApi(selectedWorkspaceId, formPayload).then(
          (headers: InitialImportResultModel) => {
            setMappings(
              headers.fileHeaders.map((header) => {
                const previousHeaderValue = mappings?.find(
                  (prevMap) => prevMap[header] !== undefined,
                )

                if (previousHeaderValue) {
                  return previousHeaderValue
                }

                return { [header]: null }
              }),
            )
            setMapId(headers.importFileId ?? "")
            setStep(step + 1)
            setLoading(false)
          },
        )
      )
    }

    //UPDATE
    if (uploadId || mapId) {
      formPayload.append("id", uploadId || mapId)

      return (
        selectedWorkspaceId &&
        updateDataMappingApi(selectedWorkspaceId, formPayload).then(
          (headers: InitialImportResultModel) => {
            setMappings(
              headers.fileHeaders.map((header) => {
                const previousHeaderValue = mappings?.find(
                  (prevMap) => prevMap[header] !== undefined,
                )

                if (previousHeaderValue) {
                  return previousHeaderValue
                }

                return { [header]: null }
              }),
            )
            setMapId(headers.importFileId ?? "")
            setStep(step + 1)
            setLoading(false)
          },
        )
      )
    }

    //NEW UPLOAD
    if (selectedWorkspaceId) {
      setLoading(true)
      importDataMappingApi(selectedWorkspaceId, formPayload).then(
        (headers: InitialImportResultModel) => {
          setMappings(
            headers.fileHeaders.map((header) => {
              return { [header]: null }
            }),
          )
          setMapId(headers.importFileId ?? "")
          setStep(step + 1)
          setLoading(false)
        },
      )
    }
  }

  const openConfirmationModal = (isCancel: boolean) => {
    setModal(
      <ConfirmationModal
        title=""
        confirmLabel="Yes, Leave"
        children={
          <div className="flex flex-col items-center gap-6">
            <Icon
              icon={ICONS.EXCLAMATION}
              className="text-yellow-600 justify-self-center"
              size={9}
            />
            <span className="text-title-3 font-semibold text-gray-900">
              You are about to leave this page!
            </span>
            <span className="text-body font-medium text-gray-500 mb-6">
              Are you sure you want continue? Your progress will be lost.
            </span>
          </div>
        }
        onConfirm={() => {
          setHasConfirmed(true)
          if (isDuplicate && mapIdRef.current) {
            dispatch.uploadProductData
              .deleteUpload({
                workspaceId: selectedWorkspaceId,
                deleteWorkspaceId: mapId,
              })
              .then(() => {
                blocker.proceed && blocker.proceed()
              })
          } else {
            if (selectedWorkspaceId) {
              if (mapIdRef.current) {
                cancelUploadApi(selectedWorkspaceId, mapIdRef.current).finally(() => {
                  blocker.proceed && blocker.proceed()
                })
              } else {
                blocker.proceed && blocker.proceed()
              }
            }
          }
        }}
        onCancel={() => blocker.reset && blocker.reset()}
      />,
    )
  }

  useEffect(() => {
    if (uploadId && selectedWorkspaceId) {
      getDataMappingByIdApi(selectedWorkspaceId, uploadId).then(
        ({ name = "", fileName = "", retailerIds, mappingList }) => {
          if (isDuplicate) {
            setMapName("")
          } else {
            setMapName(name)
          }
          setFile(new File([""], fileName))

          const matchingRetailers: RetailerSelectorOption[] = allRetailers
            .filter((retailer) => retailerIds.includes(retailer.id))
            .map((retailer) => ({
              value: retailer.id,
              label: retailer.name,
              logoUrl: retailer.logoUrl,
              handle: retailer.handle,
            }))

          setSelectedRetailers(matchingRetailers)
          if (mappingList.length > 0) {
            const initialMapping = mappingList.map((el) => {
              return { [el.fileHeader as string]: el.catalogHeader || null }
            })
            setMappings(initialMapping)
          }
        },
      )
    }
  }, [uploadId])

  useEffect(() => {
    if (blocker.state === "blocked") {
      openConfirmationModal(false)
    }
  }, [blocker.state])

  useEffect(() => {
    if (isOpen === false && previousIsOpen === true && !hasConfirmed) {
      blocker.reset && blocker.reset()
    }
  }, [isOpen, hasConfirmed])

  useEffect(() => {
    mapIdRef.current = mapId
  }, [mapId])

  return (
    <div className="field-mapping-page-container">
      <div className="stepper-container">
        <Stepper
          steps={[{ label: "" }, { label: "" }, { label: "" }, { label: "" }]}
          activeStep={step}
        />
      </div>
      <div className={`step-content-container ${step !== 1 ? "mr-3" : "mr-1"}`}>
        <div className="flex-grow">
          {
            {
              0: (
                <StepOne
                  step={step}
                  setStep={setStep}
                  mapName={mapName}
                  setMapName={setMapName}
                  file={file}
                  setFile={setFile}
                  onCancel={() => {
                    navigate(`/main/workspaces/${selectedWorkspaceId}/edit/upload`, {
                      state: 1,
                    })
                  }}
                />
              ),
              1: (
                <StepTwo
                  step={step}
                  setStep={setStep}
                  selectedRetailers={selectedRetailers}
                  setSelectedRetailers={setSelectedRetailers}
                  selectedUploadType={selectedUploadType}
                  setSelectedUploadType={setSelectedUploadType}
                  importDataMapping={importDataMapping}
                  onCancel={() => {
                    navigate(`/main/workspaces/${selectedWorkspaceId}/edit/upload`, {
                      state: 1,
                    })
                  }}
                />
              ),
              2: (
                <StepThree
                  step={step}
                  setStep={setStep}
                  dataMappingId={mapId}
                  mappings={mappings}
                  setMappings={setMappings}
                  setLoading={setLoading}
                  setFourthStepData={setFourthStepData}
                  onCancel={() => {
                    navigate(`/main/workspaces/${selectedWorkspaceId}/edit/upload`, {
                      state: 1,
                    })
                  }}
                />
              ),
              3: (
                <StepFour
                  step={step}
                  setStep={setStep}
                  setIsSaving={setIsSaving}
                  data={fourthStepData}
                  onCancel={() => {
                    navigate(`/main/workspaces/${selectedWorkspaceId}/edit/upload`, {
                      state: 1,
                    })
                  }}
                />
              ),
            }[step]
          }
          {loading && (
            <div className="absolute w-full h-full top-0 bg-white bg-opacity-50 flex items-center justify-center">
              <Loader className="w-full text-primary- mt-2" message="Loading..." />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
